import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`*`}{`T2B, max reps, 1 unbroken set`}</p>
    <p>{`*`}{`Max Height Box Jumps 5×1 to a 1RM`}</p>
    <p>{`*`}{`Deadlifts 5×1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`5 Deadlifts (275/185)(rx+ 315/225)`}</p>
    <p>{`10 Burpees`}</p>
    <p>{`15 T2B`}</p>
    <p><em parentName="p">{`*`}{`compare to 7/31/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`Reminder: Your scores for Open workout 19.3 must be submitted by
8:00pm tonight!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will from from 11:00-11:30 Saturday, March
16th.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start next Monday, March 17th.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      